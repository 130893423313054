import React, {useState, useEffect} from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {Panel} from 'components/primaries'
import {Row, Col, Input, DatePicker} from 'antd'
import {color} from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import {ZenSmartAPI} from 'utils'
import {getPostbacksRoute} from "utils/apiRoutes"
import {postbackInitialData} from "utils/panelsInitialData"
import moment from "moment/moment";

const {RangePicker} = DatePicker;
const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`
const Heading = styled.h1`
  font-size: 15px;
  font-family: 'Rubik', sans-serif;
  font-weight: bold;
`
const InputStyle = createGlobalStyle`
  .ant-input-lg::placeholder {
    color: blue;
  }
`
const PostbacksPage = (props) => {
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [ascend, setAscend] = useState(postbackInitialData)
    const [dateRange, setDateRange] = useState([moment().subtract(1, 'days'), moment().subtract(1, 'days')]);
    const fetchData = (fromDate, toDate) => {
        setLoading(true)
        ZenSmartAPI.get(getPostbacksRoute, {params: {fromDate, toDate}})
            .then((res) => {
                setLoading(false)
                setItemData(res.data)
                setOriginalData(res.data)
                setAscend(postbackInitialData)
            })
            .catch((res) => {
                setLoading(false)
            })
    }

    const searchOnArray = (anItemValue, value) => {
        console.log("Search on array value: ", value);
        return anItemValue.some(data => {
            console.log(data);
            return data.toString().toLowerCase().includes(value.toLowerCase())
        });
    }

    const searchOperation = async (value, source) => {
        console.log("value: ", value)
        console.log("source: ", source)
        if (value === '') {
            setItemData(originalData)
        } else {
            const result = await source.filter(item => {
                const itemValues = Object.values(item)
                return itemValues.some(anItemValue => {
                    return (
                        (anItemValue === null) ?
                            false :
                            Array.isArray(anItemValue) ? searchOnArray(anItemValue, value) :
                                anItemValue.value ? anItemValue.value.toString().toLowerCase().includes(value.toLowerCase()) :
                                    anItemValue.toString().toLowerCase().includes(value.toLowerCase())
                    )
                })
            })
            setItemData(result)
        }
    }

    const handleDateChange = (dates) => {
        setDateRange(dates);
        const fromDate = dates[0] ? dates[0].format('YYYY-MM-DD') : '';
        const toDate = dates[1] ? dates[1].format('YYYY-MM-DD') : '';
        fetchData(fromDate, toDate);
    }
    useEffect(() => {
        fetchData(dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD'))
    }, [])
    return (
        <>
            <Header>
                <Title>Process Center {">"} Postbacks </Title>
            </Header>
            <Panel title="Postbacks">
                <div style={{padding: "10px 50px 50px 50px"}}>
                    <Row type="flex" align="middle">
                        <InputStyle/>
                        <Heading>Date Range :</Heading>
                        <Col style={{padding: 10}}>
                            <RangePicker
                                value={dateRange}
                                onChange={handleDateChange}
                            />
                        </Col>
                        <Heading style={{marginLeft: 25}}>Search :</Heading>
                        <Col style={{ padding: 10 }}>
                            <Input.Search
                                type="text"
                                placeholder="Enter search text here..."
                                disabled={loading}
                                size="large"
                                onChange={(value) => searchOperation(value.target.value, originalData)}
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </div>
                <TableData
                    itemData={itemData}
                    loading={loading}
                    setLoading={setLoading}
                    data={data}
                    setData={setData}
                    ascend={ascend}
                    setAscend={setAscend}
                    fetchData={fetchData}/>
            </Panel>
        </>
    )
}
export default PostbacksPage