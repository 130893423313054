import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import { GetLabel, LabelConversion, addTargetSeries, addTargetSeriesOptions, addPercentageStack, tableChartOptions, gaugeChartOptions } from '../utils';
import { Panel, Button, Label } from '../../../components/zensmart-design-system';
import { Summarization } from '../partials/composites';
import Matrix from "pages/Performance/partials/sections/Matrix";
import { DataTotal, TableStyle } from "pages/Performance/partials/primaries";
import Chart from 'react-google-charts';
import { Input, Select, Row, Col, Table, Checkbox} from 'antd';
import { panelSortData } from 'utils/sortData'
import is from "is_js";
import moment from 'moment';

import {
  CaretUpOutlined,
  CaretDownOutlined
} from '@ant-design/icons';
import { set } from 'date-fns';
import { alphabeticalData } from 'utils/sortData';
import { Spin } from 'antd';

const Option = Select.Option;
const { Search } = Input

const StyledDiv = styled.div`
  width: 100%;
  height: ${props => props.height ? props.height : 'fit-content'};
  
  .widget_title {
    color: #8798AD;
    font-size: 13px;
    text-transform: uppercase;
    z-index: 9999;
    
    ${(props) =>
    props.isDashboard &&
    css`
        width: ${props => (is.existy(props.parentSize) && props.parentSize.width < 80) ? '75%' : '90%'};
        position: absolute;
        left: ${props => (props.homePage ? '12px' : '5px')}; 
    `}

    ${(props) =>
      props.homePage &&
      css`
        margin-top : 5px;
        margin-bottom : 5px;
    `}

    .bold_title {
      font-weight: bold;
      font-size: 15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 3px 7px;
      color: #194787;
    }
  }

  .widget_panel {
    padding: 36px 25px;
    overflow-y: auto;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
  }
  
  .dashboard_panel {
    width: 100%;
    border-width: 2px;
    border-radius: 6px;
    box-shadow: 0 0 1px rgba(32,32,32,.2); 
    ${(props) =>
      props.homePage &&
      css`
        border : 1px solid #0B6BF2;
    `}
    ${(props) =>
    props.sidebar &&
    css`
        &:focus {
          opacity: 0.5;
        }
    `}
  }
  .dashboard_panel:hover {
    box-shadow: 0 0 13px rgba(33,33,33,.2); 
  }
  .controls_section {
    display: flex;
    margin-bottom: 1rem;

    .widget_search {
      margin-bottom: 0;
      width: ${props => (props.showTotalCount) ? '25%' : '35%'};
    }
  
    .action_buttons {
      margin-left: auto;
      margin-right: 0;
      display: inline-flex;
      width: fit-content;
      max-width: 50%;
      flex-flow: wrap;
  
      .action_button {
        margin-right: 5px;
        display:block;
        display: inline-flex;
        min-width: fit-content;
      }
      
      .count_label {
        display: inline-flex;
        margin-right: 20px;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 15px;
      }
    }
  }
  
  .select-span {
    width: 40%;
  }
  
  .select-label {
    font-weight: bold;
    margin-left: 10%;
  }
  
  .select-dropdown {
    margin-left: 2%;
    width: 55%;
  }

  //Solution taken from https://stackoverflow.com/questions/25042874/how-can-i-change-the-css-properties-of-a-google-visualization-table-and-it-colum
  .google-visualization-table-table {
    font-family: arial, helvetica;
    font-size: ${props => (is.existy(props.parentSize)) ? (props.parentSize.width * 0.03) + 'px' : '12px'};
    cursor: default;
    margin: 0 0 10px 0;
    background: white;
    border-spacing: 0;
  }

  // this is needed to solve fickering when tool-tip is on a bar and is being hovered on in chart... 
  // Link to issue for future reference here at https://pictureworks.atlassian.net/browse/ZSMART-8179
  // solution taken from https://stackoverflow.com/questions/37902708/google-charts-tooltip-flickering 
  .google-visualization-tooltip { pointer-events: none }
`

const TableStyling = styled.div`
  overflow-y: auto;
  width: 100%;
  height: ${(props) => (props.isColumnFilters ? undefined : is.existy(props.parentSize) ? props.parentSize.height - 30 + "px" : "88%")};
 ${(props) => (props.isColumnFilters && "min-height: 10px; max-height : 88%")};
  .data_rows.even {
    /* background-color: rgba(11,107,242,0.07); */
  }

  table  {
    border-collapse: collapse;
    width: 100%;
    text-align : center;
    ${props => props.selectable && `
    .data_rows {
      cursor: pointer;
    }
    .data_rows:hover {
      background-color: #e6f7ff;
    }
    `}
  }

  thead th {
    ${props => props.fixedheader && (`
      position: sticky;
      top: 0;
    `)}
    background:#fff;
  }

  table tr td, table tr th {
    ${props => props.isCompact && (
    `padding: 0.4em;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 10rem;
      text-align : left;`
  )
  }
  }

`

const SelectionButtonStyling = styled.div`
  width: 100%;

  span {
    float: right;
    width: fit-content;

    .selection_button {
      margin-left: 5px;
    }
  }
`

const Widget = ({
  title,
  filter_column,
  hide_column,
  fixedheader,
  tight_table,
  height,
  selectable,
  actions,
  isSendingAction,
  handleResourceAction,
  primaryFilterOptions = [],
  selectionOptions,
  labelFields = [],
  report,
  dataSource,
  modifyPrimaryFilter,
  uniqueValues,
  matrixDataSource,
  searchFieldChanged,
  labelFunction,
  iconRender,
  selectedRows,
  setSelectedRows,
  actionFields,
  rowSelected,
  sorter, // required for sorting
  getType, // required for sorting
  defaultActiveFirstFilterOption = false,
  isDashboard = false,
  active = true,
  modifiedSorting,
  sidebar = false,
  parentSize,
  isCompact = false,
  dataFields,
  isLoading = false,
  dropDowns,
  onSelect,
  showTotalCount = false,
  isColumnFilters = false,
  columnFilterDatas = {},
  filterClassName = false,
  parentRef,
  matrixRef,
  refIndex,
  columnFilterObject,
  setcolumnFilterObject,
  updateMatrixFont,
  matrixOptions,
  reportURL,
  pushURL,
  homePage
}) => {
  const [selectedFilter, setSelectedFilter] = useState('')
  const [ranDefaultFirstOption, setRanDefaultFirstOption] = useState(false) // used to ensure that filter runs only once on load
  const [data, setData] = useState(dataSource);
  const [ascend, setAscend] = useState({})
  const [chartKey, setChartKey] = useState(false)
  const [filterColumnsClasses, setFilterColumnClasses] = useState(null)
  const [filterTableColumnsClasses, setFilterTableColumnClasses] = useState(null)

  const renderLabel = (item, column = '') => {
    if (iconRender) {
      return iconRender(item, _.replace(column, /.+?\./, "")
      )
    } else {
      if (labelFields && labelFields.includes(column)) {
        const labelOptions = LabelConversion(item)
        return (
          <Label status={labelOptions.status} type={labelOptions.type}>
            {labelOptions.value}
          </Label>
        )
      } else {
        return item
      }
    }
  }

  const selectRows = (numberOfRows) => {
    if (numberOfRows === 'all') {
      setSelectedRows && setSelectedRows(dataSource.map((data, index) => data.primary_key ? data.primary_key : index))
    } else {
      setSelectedRows && setSelectedRows(_.take(dataSource.map((data, index) => data.primary_key ? data.primary_key : index), numberOfRows))
    }
  }

  const sortingData = (data, property, ifNotProperty) => {
    if (ascend[property] === null) {
      setAscend({ ...report.fields, [property]: true })
    }
    else {
      ascend[property] === true ? setAscend({ ...report.fields, [property]: false }) : setAscend({ ...report.fields, [property]: true })
    }
    setData(panelSortData(data, property, ascend[property], ifNotProperty))
  }

  const getHeaders = (property, text, ifAscend) => {
    return (
      <Row type="flex" align="middle" onClick={() => sortingData(data, property, ifAscend)} style={{ cursor: "pointer" }}>
        <Col span={ascend[property] === null ? 24 : 20}>
          <p>{text}</p>
        </Col>
        {ascend[property] !== null &&
          <Col span={2}>
            <p>
              {ascend[property] === false ? <CaretDownOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} />
                : ascend[property] === true ? <CaretUpOutlined style={{ fontSize: 17, verticalAlign: "baseline", color: "grey" }} /> : ""}
            </p>
          </Col>
        }
      </Row>
    )
  }

  const actionButtons = actions && (
    <span className='action_buttons'>
      {(showTotalCount && dataSource) && <span className='count_label'>
        Total: {dataSource.length}
      </span>}
      {actions.map(action => (
        <Button
          key={action.label}
          action={action}
          loading={isSendingAction}
          className='action_button'
          isDisabled={action.disabled || isSendingAction}
          onClick={() => handleResourceAction && handleResourceAction(action)}
          size='small'
        >
          {action.label}
        </Button>
      ))}
    </span>
  )

  const primaryFilters = report && report.primary_filter && report.primary_filter.length > 0 && (
    report.primary_filter.map((filter, index) => {
      return primaryFilterOptions ? (
        <Select
          style={{
            width: '32%',
            marginLeft: 5,
            marginRight: 5
          }}
          defaultActiveFirstOption={true}
          placeholder={labelFunction ? labelFunction(filter.field) : GetLabel(filter.field)}
          onChange={value => {
            modifyPrimaryFilter(index, value)
            setSelectedFilter(value)
          }}
          value={selectedFilter ? selectedFilter : undefined}
        >
          {alphabeticalData(primaryFilterOptions).map(option => (
            <Option value={option}>{option}</Option>
          ))}
        </Select>
      ) : (
          <Input
            style={{
              width: '32%',
              marginLeft: 5,
              marginRight: 5
            }}
            placeholder={labelFunction ? labelFunction(filter.field) : GetLabel(filter.field)}
            defaultValue={filter.value}
            onChange={e => modifyPrimaryFilter(index, (e.target.value).split(','))}
          />
        )
    })
  )

  const selectionButtons = (selectionOptions && selectionOptions.length) > 0 && (
    <SelectionButtonStyling>
      <span>
        {selectionOptions.map((selection, index) => (
          <Button
            key={index}
            onClick={() => selectRows(selection)}
            size='small'
            className='selection_button'
          >
            Select {selection}
          </Button>
        ))}
      </span>
    </SelectionButtonStyling>
  )

  const selectDropDowns = dropDowns && (
    <span className="select-span">
      <span className="select-label">{dropDowns.label}</span>
      <Select className="select-dropdown" onChange={onSelect}>
        {alphabeticalData(dropDowns.options).map(option => (
          <Option value={option}>{GetLabel(option)}</Option>
        ))}
      </Select>
    </span>
  )

  const getGroupType = (group) => {
    const groupComponents = group ? group.split(".") : false
    const table = groupComponents ? groupComponents[0] : ""
    const field = groupComponents ? groupComponents[1] : ""
    
    const item = _.find(dataFields, {
      table: table,
      field: field
    })

    if (item) {
      return item.type
    }
    return ''
  }

  const calculateColumnWidths = (reportFields, dataSource) => {
    // Initialize an object to store the maximum width for each column name (alphabets count)
    const maxColumnNamesWidths = {};
    
    // Iterate through each column name to find the maximum width (alphabets count)
    reportFields.forEach(field => {
      maxColumnNamesWidths[field] = field.length;
    });
  
    // Initialize an object to store the maximum width for each column data
    const maxDataWidths = {};
  
    // Iterate through each item in the dataSource to find the maximum width for each column data
    dataSource.forEach(item => {
      reportFields.forEach(field => {
        const value = item[field];
        const currentWidth = maxDataWidths[field] || 0;
        const valueWidth = value ? value.toString().length : 0; // Calculate the length of the value (assuming string)
  
        // Update the maximum width for the column data if the current value is wider
        maxDataWidths[field] = Math.max(currentWidth, valueWidth);
      });
    });
  
    // Calculate maximum width for each column
    const maxWidths = {};
    reportFields.forEach(field => {
      const columnNameWidth = maxColumnNamesWidths[field];
      const columnDataWidth = maxDataWidths[field];
      maxWidths[field] = Math.max(columnNameWidth, columnDataWidth);
    });
  
    // Map over the report fields to create an array of column objects with calculated widths
    const columns = reportFields.map(field => ({
      dataIndex: field,
      width: maxWidths[field] ? maxWidths[field] * 8 : 150 // Multiply by a factor (e.g., 8px per character) or set a default width
    }));
  
    return columns;
  };

  const getTableColumns = () => {
    if(isDashboard){
      return getTableWithColumnsCheck()
    }else{
      if (report && report.fields) {
        const value = report.fields.map((item, index) => ({
          title: modifiedSorting ? getHeaders(item, labelFunction ? labelFunction(item) : GetLabel(item), false) : (
            labelFunction ? labelFunction(item) : GetLabel(item)
          ),
          dataIndex: item,
          key: item,
          sorter: modifiedSorting ? null : sorter,
          render: renderLabel,
          type: getType ? getType(item) : ''
        }))
        return value
  
      } else {
        return [];
      }
    }
    
  }

  const getTableWithColumnsCheck = () => {
    if (report && report.fields) {
      const columnWidths = calculateColumnWidths(report.fields, dataSource);
      
      const value = report.fields.map((item, index) => {
        const isBooleanField = typeof dataSource[0][item] === 'boolean'; // Check if the first item has a boolean value for the field
        
        return {
          title: modifiedSorting 
            ? getHeaders(item, labelFunction ? labelFunction(item) : GetLabel(item), false) 
            : (labelFunction ? labelFunction(item) : GetLabel(item)),
          dataIndex: item,
          key: item,
          sorter: modifiedSorting ? null : sorter,
          render: isBooleanField 
            ? (text) => <Checkbox checked={text} disabled={true} /> // Render a checkbox for boolean fields
            : renderLabel, // Use the default rendering function for other fields
          width: columnWidths[index].width,
          type: getType ? getType(item) : ''
        };
      });
      
      return value;
    } else {
      return [];
    }
  }

  const calculateFont = (parentSize) => {
    if (is.not.existy(parentSize)) {
      return 30;
    }

    let _fs, _n;

    const width = parentSize.width;
    const height = parentSize.height;

    if (height <= 50) {
      _n = 16;
    } else if (width <= 78) {
      _n = 37.33;
    } else {
      const _fsx = (width / 77) * 16;
      const _fsy = (height / 50) * 16;

      _fs = (width > height ? ((_fsx + _fsy) * 0.5) : _fsx);

      _n = parseFloat(_fs).toFixed(2);
    }

    return _n + 'px';
  }

  const setColumnDataFilterFunction = (value, property) => {
    setcolumnFilterObject({ ...columnFilterObject, [property]: value })
  }


  const renderColumnDatas = (data) => {
    const returnValue = Object.keys(columnFilterDatas).map((item, key) => {
      const getTableHeaderWidth = filterColumnsClasses[key];
      if (getTableHeaderWidth) {
        return <div style={{ textAlign: "center", padding: "10px 2px 15px 2px", width: getTableHeaderWidth }}>

          <Select defaultValue={""} style={{ marginTop: 10, width: '100%' }}
            optionFilterProp="children"
            showSearch
            dropdownMatchSelectWidth={false}
            onChange={(e) => setColumnDataFilterFunction(e, item)}
          >
            <Option value={""} style={{ fontWeight: "bold" }}>{columnFilterDatas[item].label}</Option>
            {columnFilterDatas[item].date_data ? alphabeticalData(columnFilterDatas[item].date_data).map(data =>
              <Option value={moment(data).format('MMM DD ddd hh:mm').toString()}>{moment(data).format('MMM DD ddd hh:mm').toString()}</Option>
            )
              : alphabeticalData(columnFilterDatas[item].data).map(data =>
                <Option value={data}>{data}</Option>
              )}
          </Select>

        </div >
      }

    })

    return <div style={{ position: "relative", overflowX: "auto", width: "100%" }}>
      <div style={{ display: "flex", width: filterTableColumnsClasses, height: "100%" }}>

        {returnValue}
      </div>
    </div>
  }

  useEffect(() => {
    if (
      defaultActiveFirstFilterOption === true &&
      primaryFilterOptions &&
      primaryFilterOptions.length > 0 &&
      dataSource.length > 0 &&
      ranDefaultFirstOption === false
    ) {
      setSelectedFilter(primaryFilterOptions[0])
      modifyPrimaryFilter(0, primaryFilterOptions[0])
      setRanDefaultFirstOption(true)
    }
    setData(dataSource)

  }, [defaultActiveFirstFilterOption, primaryFilterOptions, ranDefaultFirstOption, dataSource])

  useEffect(() => {
    if (report.fields) {
      const dataObj = {}
      report.fields.map(data => {
        dataObj[data] = null
      })
      setAscend(dataObj);
    }
  }, [report])


  //chartKey to set key props to Chart component
  //in order to force redraw chart
  useEffect(() => {
    setChartKey(prevKey => !prevKey)
  }, [parentSize])

  if (isColumnFilters) {
    const getFilterWidth = document.querySelectorAll(`th.${filterClassName}`);
    const getTableFilterWidth = document.querySelectorAll(`tr.${filterClassName}`)[0];
    if (Array.from(getFilterWidth).length > 0 && filterColumnsClasses === null) {
      const filterWidth = Array.from(getFilterWidth).map(item => item.clientWidth)
      setFilterColumnClasses(filterWidth)
      setFilterTableColumnClasses(getTableFilterWidth.clientWidth)
    }
  }

  return report ? (
    <StyledDiv
      sidebar={sidebar}
      parentSize={parentSize}
      isDashboard={isDashboard}
      height={height ? height : '100%'}
      homePage={homePage}
      showTotalCount={showTotalCount}>
      <Panel active={active} width='90%' height='100%' className={isDashboard ? 'dashboard_panel' : 'widget_panel'}>
        <div className='widget_title'>
        {title && <p className='bold_title'> <a href={reportURL} onClick={e => pushURL(e, reportURL)}>{title}</a></p>}
        </div>
        <div className='controls_section'>
          {((!isDashboard) && filter_column) && (
            <Search
              placeholder='Search'
              className='widget_search'
              onChange={e => searchFieldChanged(e.target.value)}
            />
          )}
          {!isDashboard && primaryFilters}
          {!isDashboard && selectDropDowns}
          {!isDashboard && actionButtons}
        </div>
        {!isDashboard && selectionButtons}

        {report && report.visualization && (report.visualization === "table" ||
          (report.visualization === "summary" && is.empty(report.chart_configuration))) || isLoading ? (
            <TableStyling
              fixedheader={fixedheader}
              filter_column={filter_column}
              selectable={selectable}
              isCompact={isCompact}
              parentSize={parentSize}
              isColumnFilters={isColumnFilters}
            >
              <Spin tip="Loading..." spinning={isLoading}>
                <Summarization
                  columns={getTableColumns()}
                  dataSource={dataSource ? data.map((item, index) => {
                    item.key = index; //add a key to all data source entries
                    return item;
                  }) : []}
                  actionFields={actionFields}
                  uniqueValues={uniqueValues}
                  groups={report.groups}
                  hideDropZones={true}
                  tight={tight_table}
                  hideColumn={hide_column}
                  selectedRows={selectedRows}
                  rowSelected={rowSelected}
                  isDashboard={isDashboard}
                  getGroupType={getGroupType}
                  parentSize={parentSize}
                  filterClassName={filterClassName}
                />
              </Spin>
            </TableStyling>

          ) : (report && report.visualization && report.visualization === "tabular") ? (
            <TableStyle tight={tight_table} parentSize={parentSize} isDashboard={isDashboard} scrollX={true} homePage={homePage}>
              <Table 
                style={{ height: "100%" , marginLeft : "15px" , marginTop : "32px"  }}
                columns={getTableColumns()}
                dataSource={dataSource ? data.map((item, index) => {
                  item.key = index; //add a key to all data source entries
                  return item;
                }) : []}
                pagination={false}
                scroll={is.existy(parentSize) ? { x: 'max-content', y: parentSize.height - 80 } : { x: 'max-content' }}

              />
            </TableStyle>
          ) : (report && report.visualization &&
            (report.visualization === "matrix" && is.empty(report.chart_configuration))) ? (
                <Matrix
                  style={{  padding : "8px" }}
                  dataSource={matrixDataSource}
                  groups={report.groups}
                  groupX={is.existy(report.group_matrix_x) && is.not.empty(report.group_matrix_x) ? report.group_matrix_x : []}
                  groupY={is.existy(report.group_matrix_y) && is.not.empty(report.group_matrix_y) ? report.group_matrix_y : []}
                  uniqueValues={uniqueValues}
                  displaySource={false}
                  hideDropZone={true}
                  editableReport={false}
                  summaryRow={matrixOptions.field ? matrixOptions.field : ''}
                  summaryType={matrixOptions.option ? matrixOptions.option : 'record count'}
                  groupedDate={is.existy(report.grouped_date) && is.not.empty(report.grouped_date) ? report.grouped_date : []}
                  // setGroupedDate={setGroupedDate}
                  // containsGroupedItem={containsGroupedItem}
                  dateGroupingOptions={{
                    "Year": "Y",
                    "Exact Month": "M Y",
                    "Relative Month": "F",
                    "Exact Week": "Y W",
                    "Relative Week": "W",
                    "Exact Date": "Y-m-d",
                    "Relative Date": "m-d",
                    "Relative Day": "l",
                    "Exact Hour": "Y-m-d H",
                    "Relative Hour": "H"
                  }}
                  getGroupType={getGroupType}
                  tight={isDashboard}
                  parentSize={parentSize}
                  isDashboard={isDashboard}
                  parentRef={parentRef}
                  matrixRef={matrixRef}
                  refIndex={refIndex}
                  updateMatrixFont={updateMatrixFont}
                  homePage={homePage}
                />
              ) : (report && report.visualization &&
                (report.visualization === "total" && is.empty(report.chart_configuration))) ? (
                  <DataTotal
                    style={{
                      display: 'flex',
                      margin: '0 auto',
                      fontSize: calculateFont(parentSize),
                      fontWeight: 'bold',
                      height: is.existy(parentSize) && parentSize.height <= 50 ? '57%' : '90%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '8px',
                      color: '#194787',
                    }}
                  >
                    {dataSource}
                  </DataTotal>
                ) : (report.chart_configuration && report.chart_configuration.data && (
                  <Chart
                    key={chartKey}
                    chartType={report.chart_configuration.type}
                    loader={<div>Loading Chart</div>}
                    data={report.chart_configuration.target && (report.chart_configuration.type === "BarChart" ||
                        report.chart_configuration.type === "ColumnChart" ||
                        report.chart_configuration.type === "LineChart" ||
                        report.chart_configuration.type === "Line") ? addTargetSeries(report.chart_configuration.data, report.chart_configuration) : report.chart_configuration.data}
                    options={report.chart_configuration.target && (report.chart_configuration.type === "BarChart" || report.chart_configuration.type === "ColumnChart") ? addTargetSeriesOptions(report.chart_configuration.options, report.chart_configuration.data, report.chart_configuration) :
                        report.chart_configuration.options.isStacked && report.chart_configuration.stacked_percentage ? addPercentageStack(report.chart_configuration.options) : report.chart_configuration.type === "Table" ? tableChartOptions(report.chart_configuration.options, parentSize) :
                        report.chart_configuration.type === "Gauge" ? gaugeChartOptions(report.chart_configuration.data, report.chart_configuration.options) : report.chart_configuration.options}
                    width={report.chart_configuration.width}
                    height={report.chart_configuration.height}
                    legendToggle
                  />
                )
                )}

        {isColumnFilters && filterColumnsClasses && isLoading === false &&
          <div >
            {renderColumnDatas()}
          </div>
        }

      </Panel>

    </StyledDiv>
  ) : (<div></div>)
}

export default Widget